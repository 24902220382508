@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply min-h-screen font-lato text-lg font-normal text-font-primary md:text-xl;
}

.wrapper {
  @apply mx-auto max-w-7xl px-4 sm:px-6 lg:px-8;
}

h1 {
  @apply mb-5 text-4xl font-bold lg:text-6xl;
}

h2 {
  @apply text-3xl font-bold text-font-secondary lg:text-4xl;

  span {
    @apply text-[#E73C8D];
  }
}

p {
  @apply font-light;
}

strong {
  @apply font-bold;
}
